import {
  Text,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Stack,
  useTheme,
  Tab,
  Flex,
  Box,
  Divider,
  useToast,
  Heading,
  Spacer
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { BaseModal } from '../../../../components/modals/BaseModal'
import { IOrganizationSeat, Plan, Location } from '../../../../types'

import Calendar from 'react-calendar'
import { addDays, format, endOfMonth, isBefore, isSameDay } from 'date-fns'
import { usePlans } from '../../../../utils/apiHooks'
import { ConfirmCancellationModal } from './ConfirmCancellationModal'
import { ConfirmChangeModal } from './ConfirmChangeModal'
import { useAuth } from '../../../../context/auth-context'
import styled from '@emotion/styled'
import { usePlatformTenant } from '../../../../context/platform-tenant-context'
import { FilterableSelect } from '../../../../components/FilterableSelect'
import { VerticalKeyValueText } from '../../../../components/VerticalKeyValueText'

interface IModalInput {
  seat?: IOrganizationSeat
  closeModalCallback: () => void
}

interface ITabMapping {
  tabName: string
  buttonText: string
  action: () => void
}

export const ManageMembershipModal: React.FC<IModalInput> = (input) => {
  const auth = useAuth()
  const { seat, closeModalCallback } = input
  const { platformTenant } = usePlatformTenant()
  const { plans } = usePlans(platformTenant?.id)
  const [chosenLocation, setChosenLocation] = useState<Location | null>(null)
  const [filteredPlans, setFilteredPlans] = useState<Plan[]>([])

  const [tabIndex, setTabIndex] = useState<number>(0)

  const [changePlan, setChangePlan] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState<Plan>()
  const [changeDate, setChangeDate] = useState<Date>()
  const toast = useToast()

  const [cancelPlan, setCancelPlan] = useState(false)
  const [cancellationDate, setCancellationDate] = useState<Date>()
  const theme: any = useTheme()

  const locations = platformTenant?.locations.map((location) => ({
    label: location.name,
    value: location.id
  }))

  const isFutureMembershipChange =
    !seat?.active_plan && seat?.ongoing_plan !== undefined

  const startDateOngoingPlan = seat?.ongoing_plan_start_date
    ? new Date(Date.parse(seat?.ongoing_plan_start_date))
    : undefined

  function resetModal() {
    setTabIndex(0)
    setChangePlan(false)
    setCancelPlan(false)
    setSelectedPlan(undefined)
    setChangeDate(undefined)
    setCancellationDate(undefined)
    setChosenLocation(null)
  }

  function closeModal() {
    resetModal()
    closeModalCallback()
  }

  useEffect(() => {
    if (isFutureMembershipChange) {
      setCancellationDate(new Date())
    }
  }, [isFutureMembershipChange])

  const tabMapping: ITabMapping[] = [
    {
      tabName: 'Change Plan',
      buttonText: 'Request Plan Change',
      action: () => {
        if (changeDate && selectedPlan) {
          setChangePlan(true)
        } else {
          toast({
            title: 'Incomplete Information',
            description: 'Please select a plan and a Change date.',
            status: 'error'
          })
        }
      }
    },
    {
      tabName: 'Cancel Plan',
      buttonText: 'Request Plan Cancellation',
      action: () => {
        if (cancellationDate || isFutureMembershipChange) {
          setCancelPlan(true)
        } else {
          toast({
            title: 'Incomplete Information',
            description: 'Please select valid Cancellation date.',
            status: 'error'
          })
        }
      }
    }
  ]
  const StyledTab = styled(Tab)`
    /* tab / default */
    font-weight: 600;
    font-size: 14px;
    color: ${theme.colors.brandPrimary};
    padding-bottom: 16px;
    border: unset;

    /* tab / active */
    &[aria-selected='true'] {
      border: unset;
      border-bottom: 2px solid ${theme.colors.brandPrimary};
      color: ${theme.colors.brandPrimary};
    }
  `
  if (!!!seat) {
    return null
  }
  if (cancelPlan && (cancellationDate || isFutureMembershipChange)) {
    return (
      <ConfirmCancellationModal
        seat={seat}
        cancellationDate={cancellationDate ?? new Date()}
        closeModalCallback={closeModal}
      />
    )
  }
  if (changePlan && changeDate && selectedPlan) {
    return (
      <ConfirmChangeModal
        seat={seat}
        newPlan={selectedPlan}
        changeDate={changeDate}
        closeModalCallback={closeModal}
      />
    )
  }

  const handleLocationSelected = (location: Location) => {
    if (location) {
      setChosenLocation(location)
      const plansToShow = plans.filter(
        (p) =>
          (p.location?.id === location.id || p.location === null) &&
          // This ensures the plan is a valid plan (not an addon plan),
          // prevents improper DB state
          !p.addon_plan
      )
      setFilteredPlans(plansToShow)
      location !== chosenLocation && setSelectedPlan(undefined)
    }
  }

  // allow space admins to cancel plans at the end of the current month
  // all other users must follow the 30 day rule
  const minimumCancellationDate = () => {
    if (auth.me?.user.is_space_admin) {
      if (isFutureMembershipChange) return new Date()
      return endOfMonth(new Date())
    }
    return addDays(new Date(), 30)
  }

  return (
    <BaseModal
      title="Manage Membership"
      isOpen={seat !== undefined}
      closeModalCallback={closeModal}
      primaryButtonText={tabMapping[tabIndex].buttonText}
      primaryAction={tabMapping[tabIndex].action}
      secondaryAction={closeModal}
      size="3xl"
    >
      <Flex>
        <Box w="60%">
          <Tabs mt={6} mb={4} onChange={(index) => setTabIndex(index)}>
            <TabList>
              {tabMapping.map((tab, i) => (
                <StyledTab key={i}>{tab.tabName}</StyledTab>
              ))}
            </TabList>

            <TabPanels mt={2}>
              <TabPanel>
                {seat && locations && (
                  <>
                    <FilterableSelect
                      dataTestId="location-button"
                      initialValue={
                        seat.active_plan && seat.active_plan.location
                          ? seat.active_plan.location.id
                          : seat.ongoing_plan?.location?.id
                      }
                      items={locations}
                      name={'Choose a Location'}
                      onSelect={(n) => {
                        let location = platformTenant?.locations.find(
                          (l) => l.id === n
                        )
                        if (location) {
                          handleLocationSelected(location)
                        }
                      }}
                    />
                    <FilterableSelect
                      items={filteredPlans.map((plan) => ({
                        label: plan.name,
                        value: plan.id
                      }))}
                      name="Select a plan"
                      initialValue={selectedPlan?.id}
                      onSelect={(n) =>
                        setSelectedPlan(plans.find((plan) => plan.id === n))
                      }
                    />
                  </>
                )}
                <Flex mb={2}>
                  <Text fontWeight="bold" fontSize="14px">
                    Selected Change Date:{' '}
                  </Text>
                  <Text fontSize="14px">
                    {changeDate ? format(changeDate, 'dd/MM/yyyy') : ''}
                  </Text>
                </Flex>
                <Calendar
                  selectRange={false}
                  onChange={(date) => {
                    setChangeDate(
                      Array.isArray(date) ? (date[0] as Date) : (date as Date)
                    )
                  }}
                  value={changeDate}
                  minDate={new Date()}
                />
              </TabPanel>

              <TabPanel>
                <Flex mb={2}>
                  <Text color=" #2f4858" fontWeight="bold" fontSize="14px">
                    Select the cancellation date for this membership:&nbsp;
                  </Text>
                  <Text fontSize="14px">
                    {cancellationDate
                      ? format(cancellationDate, 'dd/MM/yyyy')
                      : ''}
                  </Text>
                </Flex>
                <Calendar
                  selectRange={false}
                  onChange={(date) => {
                    setCancellationDate(
                      Array.isArray(date) ? (date[0] as Date) : (date as Date)
                    )
                  }}
                  value={cancellationDate}
                  minDate={minimumCancellationDate()}
                />
                <Divider mt={4} />
                {!auth.me?.user.is_space_admin && (
                  <Text as={'i'}>
                    Note: 30 Days notice must be given when cancelling
                    memberships
                  </Text>
                )}
                {isFutureMembershipChange &&
                  startDateOngoingPlan &&
                  cancellationDate && (
                    <Stack>
                      {isBefore(cancellationDate, startDateOngoingPlan) ||
                      isSameDay(cancellationDate, startDateOngoingPlan) ? (
                        <Text mt={4}>
                          This membership will be ended and any issued invoice
                          that has not been paid will be voided.
                        </Text>
                      ) : (
                        <Text mt={4}>
                          This membership will be adjusted and will not be
                          renewed for the another period.
                        </Text>
                      )}
                    </Stack>
                  )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Spacer />
        <Box w="30%">
          <Stack spacing="3">
            <Box h={'57px'} />
            <Heading size="md">Membership status</Heading>
            {seat.active_plan && (
              <VerticalKeyValueText
                title="Current Plan"
                value={seat?.active_plan?.name}
              />
            )}
            <Divider />
            <VerticalKeyValueText
              wrap={true}
              title="Future Plan"
              value={
                seat.ongoing_plan
                  ? seat.ongoing_plan.name
                  : seat?.active_plan?.name
              }
            />
            {!seat.active_plan
              ? seat?.ongoing_plan_start_date && (
                  <>
                    <VerticalKeyValueText
                      title="Location"
                      value={seat?.ongoing_plan?.location?.name}
                    />
                    <VerticalKeyValueText
                      title="Start Date"
                      value={seat?.ongoing_plan_start_date}
                    />
                    <VerticalKeyValueText
                      title="Description"
                      value={seat?.ongoing_plan?.description}
                    />
                  </>
                )
              : null}

            <VerticalKeyValueText
              wrap={true}
              title="Assigned Member"
              value={
                seat.organizational_unit
                  ? seat.organizational_unit.user?.name
                  : 'Unassigned'
              }
            />
          </Stack>
        </Box>
      </Flex>
    </BaseModal>
  )
}
